import { displayTextWithEffect } from './text.js';

function attachEventListenersCookies() {
    const acceptCookiesButtons = document.querySelectorAll('#accept-cookies, #accept-cookies-footer');
    const declineCookiesButtons = document.querySelectorAll('#decline-cookies, #decline-cookies-footer');
    const resetCookiesButton = document.getElementById('reset-cookies');
    const cookieBanner = document.getElementById('cookie-banner');

    acceptCookiesButtons.forEach(button => {
        if (button) {
            button.addEventListener('click', () => {
                document.cookie = "cookies_accepted=true; path=/; max-age=" + 60*60*24*365;
                document.cookie = "cookies_declined=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; // Supprimer le cookie de refus
                if (cookieBanner) {
                    cookieBanner.style.display = 'none';
                }
                updateCookieStatus(); // Mettre à jour l'état des cookies
            });
        }
    });

    declineCookiesButtons.forEach(button => {
        if (button) {
            button.addEventListener('click', () => {
                document.cookie = "cookies_declined=true; path=/; max-age=" + 60*60*24*365;
                document.cookie = "cookies_accepted=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; // Supprimer le cookie d'acceptation
                if (cookieBanner) {
                    cookieBanner.style.display = 'none';
                }
                updateCookieStatus(); // Mettre à jour l'état des cookies
            });
        }
    });

    if (resetCookiesButton) {
        resetCookiesButton.addEventListener('click', resetCookiePreferences);
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const cookieBanner = document.getElementById('cookie-banner');
    const cookieText = document.querySelector('.cookie-banner p');
    const acceptButton = document.getElementById('accept-cookies-footer');
    const declineButton = document.getElementById('decline-cookies-footer');
    const learnMoreLink = document.getElementById('privacy-link');

    attachEventListenersCookies();
    updateCookieStatus(); // Mettre à jour l'état des cookies lors du chargement de la page

    if (cookieBanner && !getCookie('cookies_accepted') && !getCookie('cookies_declined')) {
        cookieBanner.style.display = 'block';
        displayTextWithEffect(cookieText, cookieText.textContent, 100, 10, 10); // Utiliser des valeurs plus petites pour l'effet
        displayTextWithEffect(acceptButton, acceptButton.textContent, 100, 10, 10); // Effet sur le bouton "Accept"
        displayTextWithEffect(declineButton, declineButton.textContent, 100, 10, 10); // Effet sur le bouton "Decline"
        displayTextWithEffect(learnMoreLink, learnMoreLink.textContent, 100, 10, 10); // Effet sur le lien "Learn more"
    }

    // Observateur de mutations pour détecter les changements dans le DOM
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                attachEventListenersCookies();
            }
        });
    });

    observer.observe(document.body, { childList: true, subtree: true });
});

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
}

function resetCookiePreferences() {
    document.cookie = "cookies_accepted=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "cookies_declined=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    const cookieBanner = document.getElementById('cookie-banner');
    const acceptButton = document.getElementById('accept-cookies-footer');
    const declineButton = document.getElementById('decline-cookies-footer');
    const learnMoreLink = document.getElementById('privacy-link');
    if (cookieBanner) {
        cookieBanner.style.display = 'block';
        const cookieText = document.querySelector('.cookie-banner p');
        displayTextWithEffect(cookieText, cookieText.textContent, 100, 10, 10); // Utiliser des valeurs plus petites pour l'effet
        displayTextWithEffect(acceptButton, acceptButton.textContent, 100, 10, 10); // Effet sur le bouton "Accept"
        displayTextWithEffect(declineButton, declineButton.textContent, 100, 10, 10); // Effet sur le bouton "Decline"
        displayTextWithEffect(learnMoreLink, learnMoreLink.textContent, 100, 10, 10); // Effet sur le lien "Learn more"
    }
    updateCookieStatus(); // Mettre à jour l'état des cookies
}

function updateCookieStatus() {
    const cookieStatus = document.getElementById('cookie-status');
    if (cookieStatus) {
        if (getCookie('cookies_accepted')) {
            cookieStatus.textContent = 'Cookies are currently accepted.';
        } else if (getCookie('cookies_declined')) {
            cookieStatus.textContent = 'Cookies are currently declined.';
        } else {
            cookieStatus.textContent = 'No cookie preference set.';
        }
    }
}

// Exemple de vérification
if (getCookie('cookies_accepted')) {
    console.log('Cookies have been accepted.');
} else if (getCookie('cookies_declined')) {
    console.log('Cookies have been declined.');
} else {
    console.log('No cookie preference set.');
}

export { attachEventListenersCookies, resetCookiePreferences };