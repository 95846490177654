import * as THREE from 'three';

let scene, camera, renderer, particles, particleSystem;
let mouseX = 0, mouseY = 0;
const particleCount = 2500; // Densité des particules
const originalPositions = new Float32Array(particleCount * 3); // Stocker les positions d'origine
let animationId; // Stocker l'ID de l'animation
let canvas; // Stocker une référence au canvas

export function init() {
    // Créer la scène
    scene = new THREE.Scene();

    // Créer la caméra
    camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 10000);
    camera.position.z = 750;

    // Créer le renderer
    renderer = new THREE.WebGLRenderer({ alpha: true }); // Permettre la transparence
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.domElement.style.position = 'fixed';
    renderer.domElement.style.top = '0';
    renderer.domElement.style.left = '0';
    renderer.domElement.style.zIndex = '-1'; // Positionner derrière le contenu
    document.body.appendChild(renderer.domElement);
    canvas = renderer.domElement; // Stocker une référence au canvas

    // Créer les particules
    particles = new THREE.BufferGeometry();
    const positions = new Float32Array(particleCount * 3);
    const sizes = new Float32Array(particleCount);

    for (let i = 0; i < particleCount; i++) {
        const x = Math.random() * window.innerWidth - window.innerWidth / 2;
        const y = Math.random() * window.innerHeight - window.innerHeight / 2;
        const z = 0; // Fixer la position Z à 0

        positions[i * 3] = x;
        positions[i * 3 + 1] = y;
        positions[i * 3 + 2] = z;

        originalPositions[i * 3] = x;
        originalPositions[i * 3 + 1] = y;
        originalPositions[i * 3 + 2] = z;

        // Ajuster la taille des particules pour donner l'illusion de profondeur
        sizes[i] = Math.random() * 3 + 1; // Taille aléatoire entre 1 et 4
    }

    particles.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    particles.setAttribute('size', new THREE.BufferAttribute(sizes, 1));

    const material = new THREE.PointsMaterial({ color: 0xffffff, size: 2, sizeAttenuation: true });
    particleSystem = new THREE.Points(particles, material);
    scene.add(particleSystem);

    // Ajouter les écouteurs d'événements
    document.addEventListener('mousemove', onDocumentMouseMove, false);
    window.addEventListener('resize', onWindowResize, false);

    animate();
}

function onDocumentMouseMove(event) {
    mouseX = (event.clientX / window.innerWidth) * 2 - 1;
    mouseY = -(event.clientY / window.innerHeight) * 2 + 1;
}

function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
    resizeParticles();
}

function resizeParticles() {
    const positions = particles.attributes.position.array;
    for (let i = 0; i < particleCount; i++) {
        const x = Math.random() * window.innerWidth - window.innerWidth / 2;
        const y = Math.random() * window.innerHeight - window.innerHeight / 2;

        positions[i * 3] = x;
        positions[i * 3 + 1] = y;
        originalPositions[i * 3] = x;
        originalPositions[i * 3 + 1] = y;
    }
    particles.attributes.position.needsUpdate = true;
}

function distance(x1, y1, x2, y2) {
    return Math.sqrt(Math.pow((x1 - x2), 2) + Math.pow((y1 - y2), 2));
}

function animate() {
    animationId = requestAnimationFrame(animate);

    // Faire bouger les particules en fonction de la position de la souris
    const positions = particles.attributes.position.array;
    for (let i = 0; i < particleCount; i++) {
        const dx = (positions[i * 3] - mouseX * window.innerWidth / 2);
        const dy = (positions[i * 3 + 1] - mouseY * window.innerHeight / 2);
        const dist = distance(positions[i * 3], positions[i * 3 + 1], mouseX * window.innerWidth / 2, mouseY * window.innerHeight / 2);

        if (dist < 75) {
            positions[i * 3] += dx / dist * 5; // Réduire la force de l'écartement
            positions[i * 3 + 1] += dy / dist * 5; // Réduire la force de l'écartement
        } else {
            // Ramener les particules à leur position d'origine
            positions[i * 3] += (originalPositions[i * 3] - positions[i * 3]) * 0.05;
            positions[i * 3 + 1] += (originalPositions[i * 3 + 1] - positions[i * 3 + 1]) * 0.05;
        }
    }
    particles.attributes.position.needsUpdate = true;

    renderer.render(scene, camera);
}

export function cleanup() {
    // Annuler l'animation
    if (animationId) {
        cancelAnimationFrame(animationId);
    }
    // Supprimer les particules de la scène
    if (scene && particleSystem) {
        scene.remove(particleSystem);
    }
    // Détruire la géométrie et le matériau des particules
    if (particles) {
        // if (particles.attributes.position) particles.attributes.position.dispose();
        // if (particles.attributes.size) particles.attributes.size.dispose();
        particles.dispose();
    }
    if (particleSystem && particleSystem.material) {
        particleSystem.material.dispose();
    }
    // Détruire le renderer
    if (renderer) {
        renderer.dispose();
    }
    // Supprimer le canvas du DOM
    if (canvas && canvas.parentNode) {
        canvas.parentNode.removeChild(canvas);
    }
    // Détacher les écouteurs d'événements
    document.removeEventListener('mousemove', onDocumentMouseMove, false);
    window.removeEventListener('resize', onWindowResize, false);
}