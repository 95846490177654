import { attachEventListenersCookies } from './cookies.js'; // Importer la fonction attachEventListeners
import { init as initBackgroundEffect, cleanup as cleanupBackgroundEffect } from './backgroundEffect.js'; // Importer les fonctions init et cleanup de backgroundEffect.js

export function loadPage(page) {
    const content = document.getElementById('content');
    fetch(`pages/${page}.html`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Page not found');
            }
            return response.text();
        })
        .then(html => {
            content.innerHTML = html;
            cleanupBackgroundEffect();
            if (page === 'home') {
                import('./initApp.js').then(module => {
                    const initializeHomePage = module.initializeHomePage;
                    initializeHomePage();
                });
            } else {
                initBackgroundEffect();
            }
            attachEventListenersCookies(); // Attacher les événements après le chargement de la page
            attachPageSpecificEventListeners(page); // Attacher les événements spécifiques à la page
        })
        .catch(error => {
            console.error('Erreur lors du chargement de la page:', error);
            loadNotFoundPage();
        });
}

function loadNotFoundPage() {
    const content = document.getElementById('content');
    fetch('pages/notFound.html')
        .then(response => response.text())
        .then(html => {
            content.innerHTML = html;
            cleanupBackgroundEffect();
            initBackgroundEffect();
            attachPageSpecificEventListeners('notFound');
        })
        .catch(error => console.error('Erreur lors du chargement de la page notFound:', error));
}

function attachPageSpecificEventListeners(page) {
    console.log('Attaching event listeners for page:', page);
    if (page === 'privacy') {
        const cookiesLink = document.getElementById('cookies-link');
        if (cookiesLink) {
            cookiesLink.addEventListener('click', () => loadPage('cookies'));
        }
    }
    if (page === 'notFound') {
        const homeLink = document.getElementById('home-link');
        if (homeLink) {
            homeLink.addEventListener('click', () => loadPage('home'));
        }
    }
    if (page === 'about') {
        const cookiesLink = document.getElementById('contact-link');
        if (cookiesLink) {
            cookiesLink.addEventListener('click', () => loadPage('contact'));
        }
    }
}