export function alternateLetters(element) {
    const originalText = element.textContent;
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-=[]{}|;:\'",.<>?/`~';
    let interval;
    let timeout;

    element.addEventListener('mouseover', () => {
        interval = setInterval(() => {
            element.textContent = originalText.split('').map(char => {
                return Math.random() > 0.5 ? characters.charAt(Math.floor(Math.random() * characters.length)) : char;
            }).join('');
        }, 30);

        timeout = setTimeout(() => {
            clearInterval(interval);
            element.textContent = originalText;
        }, 750); // Arrêter après 1 secondes
    });

    element.addEventListener('mouseout', () => {
        clearInterval(interval);
        clearTimeout(timeout);
        element.textContent = originalText;
    });
}

export function displayTextWithEffect(element, text, alternateDuration = 150, alternateIntervalTime = 30, displayIntervalTime = 15) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-=[]{}|;:\'",.<>?/`~';
    let index = 0;
    let interval;
    let alternateIntervals = [];

    function showNextCharacter() {
        if (index < text.length) {
            element.textContent += text.charAt(index);
            startAlternating(index);
            index++;
        } else {
            clearInterval(interval);
            stopAlternating();
        }
    }

    function startAlternating(charIndex) {
        const alternateInterval = setInterval(() => {
            const originalText = text.slice(0, charIndex + 1);
            const lastCharacters = originalText.slice(-5); // Les 5 derniers caractères
            const newText = originalText.slice(0, -5) + lastCharacters.split('').map(char => {
                return Math.random() > 0.5 ? characters.charAt(Math.floor(Math.random() * characters.length)) : char;
            }).join('');
            element.textContent = newText;
        }, alternateIntervalTime);

        alternateIntervals.push({ charIndex, interval: alternateInterval });

        setTimeout(() => {
            clearInterval(alternateInterval);
            element.textContent = text.slice(0, charIndex + 1);
        }, alternateDuration);
    }

    function stopAlternating() {
        alternateIntervals.forEach(({ interval }) => clearInterval(interval));
        alternateIntervals = [];
    }

    element.textContent = '';
    interval = setInterval(showNextCharacter, displayIntervalTime); // Afficher un caractère toutes les 100ms
}
