import { loadPage } from './router.js';
import { alternateLetters } from './text.js';

document.addEventListener('DOMContentLoaded', () => {
    document.getElementById('logo').addEventListener('click', () => loadPage('home'));
    document.getElementById('home-link').addEventListener('click', () => loadPage('home'));
    document.getElementById('contact-link-footer').addEventListener('click', () => loadPage('contact'));
    document.getElementById('news-link').addEventListener('click', () => loadPage('news'));
    document.getElementById('about-link').addEventListener('click', () => loadPage('about'));
    document.getElementById('terms-link').addEventListener('click', () => loadPage('terms'));
    document.getElementById('privacy-link').addEventListener('click', () => loadPage('privacy'));
    document.getElementById('privacy-link-footer').addEventListener('click', () => loadPage('privacy'));
    document.getElementById('security-link').addEventListener('click', () => loadPage('security'));
    document.getElementById('status-link').addEventListener('click', () => loadPage('status'));
    document.getElementById('docs-link').addEventListener('click', () => loadPage('docs'));
    document.getElementById('cookies-link-footer').addEventListener('click', () => loadPage('cookies'));

    const headerElements = document.querySelectorAll('header h1, header nav a');
    headerElements.forEach(element => {
        alternateLetters(element);
    });

    // Charger la page d'accueil par défaut
    loadPage('home');
});